import './App.css';
import {useEntries} from './hooks/useEntries';

function App() {
  const repeatCount = 10;
  const results = useEntries('meekb-modnar', [
    'monument_output',
    'unique_magic_armor_output',
    'unique_magic_item_output',
    'unique_magic_weapon_output',
    'npc_output',
    'location_output',
    'encounter_output',
    'quest_output',
    'trap_output',
  ], repeatCount);

  const makeOrderedList = (entries, listIndex) => {
    const listMarkup = entries.map(({list, value}, index) => (
      <li key={`li-${list}`} dangerouslySetInnerHTML={{ __html: value }}></li>
    ));
    return (<ol>{listMarkup}</ol>);
  };

  const makeDefinitionList = (listData) => {
    const listMarkup = listData.map(({list, values}, index) => (
      <>
        <dt key={`dt-${index}`}>{list}</dt>
        <dd key={`dd-${index}`}>{values.length === 1 ? values[0].value : makeOrderedList(values, index)}</dd>
      </>
    ));
    return (<dl>{listMarkup}</dl>);
  };


  setTimeout(async () => {
    const baseOptions = {
      theme: 'modnar',
      interactive: true,
      allowHTML: true,
      appendTo: window.document.body,
    };
    while (!window.tippy) await new Promise(r => setTimeout(r, 100));
    const els = document.querySelectorAll("[class^=______tippy-tooltip-]");
    if (!els || els.length === 0) return;
    els.forEach((el) => {
      const options = {
        ...baseOptions,
        content: el.dataset.tooltipContent.replace(/rightAngleBracket82857294710837/g, ">").replace(/quote82857294710837/g, "\""),
      };
      window.tippy(el, options);
    });
  }, 200);

  setTimeout(async () => {
    if (!window.meekbTooltipCopy) {
      window.meekbTooltipCopy = async (el) => {
        const text = el.dataset.copy || '';
        if (navigator.clipboard) {
          try {
            await navigator.clipboard.writeText(text).then(() => {
              el.textContent = 'Copied!';
            });
          } catch (error) {
            console.error(error.message);
          }
          return;
        }
        const textArea = document.createElement("textarea");
        try {
          textArea.value = text;
          // avoid scrolling to bottom
          textArea.style.top = "0";
          textArea.style.left = "0";
          textArea.style.position = "fixed";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          document.execCommand('copy');
          el.textContent = 'Copied!';
        } catch (error) {
          console.error(error.message);
        }
        document.body.removeChild(textArea);
      }
    }
  }, 200);

  return (
    <div class="container">
      <div class="box">
        <h1 class="header">
          <span class="logo" />
          <span class="text">MODNAR</span>
        </h1>
        {makeDefinitionList(results)}
      </div>
    </div>
  );
}

export default App;
