import {useFetch} from './useFetch';

const useEntries = (generator, lists, repeatCount) => {
  const loadingFallback = [{list: 'Stil loading:', values: [{list: 'loading', value: 'please wait'}]}];
  const errorFallback = [{list: 'Unknown error:', values: [{list: 'error', value: 'missing data'}]}];
  const domain = 'https://perchance.meekbarbarian.com';
  const repeat = isNaN(repeatCount) ? 1 : parseInt(repeatCount, 10);
  const listsToMap = Array.isArray(lists) ? lists : [lists];
  const listParams = listsToMap.map((list) => `lists=${list}`).join('&');
  const timestamp = Date.now();
  const url = `${domain}/random?t=${timestamp}&generator=${generator}&repeat=${repeat}&${listParams}`;
  console.log('useEntries', {url, listParams, repeatCount, fallback: errorFallback});

  const {loading, error, data} = useFetch({url, errorFallback});
  if (loading) return loadingFallback;
  if (error) return errorFallback;
  return data;
}

export {useEntries};
